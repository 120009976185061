
export default function windowScroll(eventListener) {
  if (typeof eventListener !== 'function') return { detach: () => { } };

  let scrollX = 0;
  let scrollY = 0;
  let ticking = false;

  const scrollHandler = event => {
    scrollX = window.scrollX;
    scrollY = window.scrollY;

    if (ticking === false) {

      window.requestAnimationFrame(function () {
        eventListener(event, scrollX, scrollY);
        ticking = false;
      });

      ticking = true;
    }

  };

  window.addEventListener('scroll', scrollHandler);

  return {
    detach: () => {
      return window.removeEventListener('scroll', scrollHandler);
    }
  };

}
