import ReactDOM from 'react-dom';
import forEachElement from './forEachElement';

export default function renderReactComponent(component, selector) {
  const containers = document.querySelectorAll(selector);
  forEachElement(containers, container => {
    ReactDOM.render(component, container);
  });
}

export function renderReactComponentInto(component, container) {
  ReactDOM.render(component, container);
}
