
import numFromPx from './numFromPx';

export default function getElementDimensions(element, subtractPadding = false) {

  if (!element) {
    return {
      width: 0,
      height: 0,
    };
  }

  const elementBounding = element.getBoundingClientRect();
  const elementStyle = window.getComputedStyle(element);

  let elementDimensions = {
    width: elementBounding.width,
    height: elementBounding.height,
  };

  if (subtractPadding) {
    elementDimensions.width = elementDimensions.width - numFromPx(elementStyle.paddingLeft) - numFromPx(elementStyle.paddingRight);
    elementDimensions.height = elementDimensions.height - numFromPx(elementStyle.paddingTop) - numFromPx(elementStyle.paddingBottom);
  }

  return elementDimensions;
}
