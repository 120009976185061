import React from 'react';
import './styles.scss';
import SimpleReactLightbox, { SRLWrapper, useLightbox } from 'simple-react-lightbox';

const Lightbox = (props) => {
  const { galleryImages } = props;

  const options = {
    settings: {
      overlayColor: 'rgba(118, 141, 154, 0.8)',
    },
    buttons: {
      backgroundColor: '#fff',
      iconColor: '#1f394c',
      showAutoplayButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
      showThumbnailsButton: false,
    },
  };

  return (
    <SimpleReactLightbox>
      <SRLWrapper options={options}>
        <ul className="blocks-gallery-grid">
          {galleryImages.map(({ i, url, thumbmnail, caption }) => (
            <li className="blocks-gallery-item" key={i}>
              <figure>
                {url && (
                  <a href={url}>
                    <img src={thumbmnail} alt={caption} />
                  </a>
                )}
                {!url && <img src={thumbmnail} alt={caption} />}
              </figure>
            </li>
          ))}
          <li className="blocks-gallery-button">
            <ViewGallery />
          </li>
        </ul>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
};

const ViewGallery = props => {
  const { openLightbox } = useLightbox();
  return (
    <button className="Button" onClick={() => openLightbox(props.imageToOpen)}>
      View Gallery
    </button>
  );
};

export default Lightbox;
