import React from 'react';
import windowLoad from '../../utils/windowLoad';
import forEachElement from '../../utils/forEachElement';
import { renderReactComponentInto } from '../../utils/renderReactComponent';
import Lightbox from '../Lightbox';
import './styles.scss';

windowLoad(() => {
  const galleries = document.querySelectorAll('.wp-block-gallery');
  forEachElement(galleries, setupGallery);
});

function setupGallery(galleryElement) {

  let galleryImages = [];
  const galleryItems = galleryElement.querySelectorAll('.blocks-gallery-item');

  forEachElement(galleryItems, (item, i) => {
    const link = item.querySelector('a');
    const thumbmnail = item.querySelector('img').getAttribute('src');
    const figcaption = item.querySelector('figcaption');
    const url = link ? link.getAttribute('href') : null;
    const caption = figcaption ? figcaption.innerHTML : '';
    galleryImages.push({ i, url, thumbmnail, caption });
  });

  const galleryClass = galleryElement.getAttribute('class');

  renderReactComponentInto(<Lightbox className={galleryClass} galleryImages={galleryImages} />, galleryElement);

}
