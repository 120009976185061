import windowLoad from '../../utils/windowLoad';
import windowResize from '../../utils/windowResize';
import windowScroll from '../../utils/windowScroll';
import getElementPosition from '../../utils/getElementPosition';
import getElementDimensions from '../../utils/getElementDimensions';
import './styles.scss';

windowLoad(() => {
  const footerBranding = document.getElementById('footer-branding');
  const footerBrandingInner = document.getElementById('footer-branding-inner');
  const footerBrandingSvg = footerBrandingInner.querySelector('svg');

  const setFooterBrandingSize = () => {
    const footerBrandingPosition = getElementPosition(footerBranding);
    const innerDimensions = getElementDimensions(footerBrandingInner);
    const innerHeight = innerDimensions.height * 2;
    const fromBottom = footerBrandingPosition.position.bottom;
    let percentageVisible = 0;

    if (fromBottom > 0) {

      if (fromBottom <= innerHeight) {
        percentageVisible = fromBottom / innerHeight;
      } else {
        percentageVisible = 1;
      }

    } else {
      percentageVisible = 0;
    }

    footerBrandingSvg.style.width = `${innerDimensions.width}px`;
    footerBrandingSvg.style.height = `${percentageVisible * innerDimensions.height}px`;
  }

  windowScroll(setFooterBrandingSize);
  windowResize(setFooterBrandingSize);

});
