import 'url-search-params-polyfill';

export default function getQueryStringParam(key, defaultValue) {
  const queryStringParams = new URLSearchParams(window.location.search);
  if (queryStringParams.has(key)) {
    return queryStringParams.get(key);
  } else {
    return defaultValue;
  }
}
