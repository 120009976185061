import windowLoad from '../../utils/windowLoad';
import forEachElement from '../../utils/forEachElement';
import './styles.scss';

function insertQuoteIcon(into) {

  const icon = document.createElement('div');
    icon.classList.add('Icon');
    icon.classList.add('Icon--orange');
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('viewBox', '0 0 60 50');
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('d', `M26,36.8C26,44.7,20.4,50,13.2,50C5.5,50,0,44.5,0,36.8c0-3.7,0.6-6.5,3.5-13.9L12,0h12.8l-6.4,24.7
    C23.1,26.6,26,30.8,26,36.8z M52.5,24.7L58.9,0H46l-8.5,22.9C34.7,30.3,34,33.1,34,36.8C34,44.5,39.5,50,47.2,50
    C54.4,50,60,44.7,60,36.8C60,30.8,57.1,26.6,52.5,24.7z`);

  icon.appendChild(svg);
  svg.appendChild(path);

  into.insertBefore(icon, into.querySelector('p:first-child'));
}

windowLoad(() => {
  const quotes = document.querySelectorAll('.wp-block-pullquote > blockquote');
  forEachElement(quotes, insertQuoteIcon);
});
