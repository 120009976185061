import getElementPosition from '../../utils/getElementPosition';
import getElementDimensions from '../../utils/getElementDimensions';
import windowLoad from '../../utils/windowLoad';
import forEachElement from '../../utils/forEachElement';
import isTouch from '../../utils/isTouch';
import getFoocusableElements from '../../utils/getFoocusableElements';
import elementKeypress from '../../utils/elementKeypress';

const quickNav = document.querySelector('.Header__quick-nav');
const quickNavTooltip = quickNav.querySelector('.Header__quick-nav__tooltip');
const quickNavTooltipArrow = quickNav.querySelector('.Header__quick-nav__tooltip__arrow');

hideTooltip();

let activeUl = null;
let leaveTimeout = null;

windowLoad(e => {

  setupQuickNav();

  quickNav.addEventListener('mouseover', () => {
    showTooltip();
  });
  
  quickNav.addEventListener('mouseleave', () => {
    leaveTimeout = setTimeout(() => {
      hideTooltip();
      hideUl(activeUl);
    }, 300);
  });

});

function setupQuickNav() {
  const quickNavIcons = quickNav.querySelectorAll('.Header__quick-nav__icons > li');
  const quickNavLinks = quickNav.querySelectorAll('.Header__quick-nav__icons > li > a');

  const handleIconAction = (icon) => {
    clearTimeout(leaveTimeout);
    leaveTimeout = null;

    const ul = icon.querySelector('ul');
    alignUl(ul);
    setTooltipOffset(ul);
    setTooltipSize(ul);
    hideUl(activeUl);
    showUl(ul);
    return ul;
  };

  forEachElement(quickNavIcons, icon => {
    icon.addEventListener('mouseover', () => handleIconAction(icon));
  });

  const handleIconLinkKeypress = (link) => {
    const linkParent = link.parentNode;
    showTooltip();
    const ul = handleIconAction(linkParent);
    const focusableElements = getFoocusableElements(ul);
    focusableElements[0].focus();
  };

  forEachElement(quickNavLinks, link => {
    
    elementKeypress(link, (e, key) => {
      if (key === 'Enter') {
        e.preventDefault();
        handleIconLinkKeypress(link);
      }
    });

    link.addEventListener('focus', () => {
      hideUl(activeUl);
      hideTooltip();
    });

    link.addEventListener('click', e => {
      if (isTouch()) {
        e.preventDefault();
        return;
      }
    });

  });

}

function alignUl(ul) {
  ul.style.transform = ``;

  const ulDimensions = getElementDimensions(ul);
  const ulPosition = getElementPosition(ul, quickNav);
  const tooltipTop = 10;

  let offset = ulDimensions.width / 2;
  const offsetLeft = (ulPosition.position.left - offset) - tooltipTop;
  const offsetRight = (ulPosition.position.right + offset) - tooltipTop;

  if (offsetLeft < 0 || offsetRight < 0) {

    if (offsetLeft < 0) {
      offset = offset - Math.abs(offsetLeft);
      ul.setAttribute('data-offset', offsetLeft);
    }

    if (offsetRight < 0) {
      offset = offset + Math.abs(offsetRight);
      ul.setAttribute('data-offset', -(offsetRight));
    }
    
  } else {
    ul.setAttribute('data-offset', 0);
  }

  ul.style.transform = `translateX(${-(offset)}px)`;
}

function setTooltipOffset(ul) {
  const ulPosition = getElementPosition(ul, quickNav);
  quickNavTooltip.style.left = `${ulPosition.offset.left}px`;
  quickNavTooltip.style.top = `${ulPosition.offset.top}px`;
  const arrowOffset = Number(ul.getAttribute('data-offset'));
  if (arrowOffset !== 0) {
    quickNavTooltipArrow.style.transform = `translateX(${arrowOffset}px)`;
  } else {
    quickNavTooltipArrow.style.transform = ``;
  }
}

function setTooltipSize(ul) {
  const ulDimensions = getElementDimensions(ul);
  quickNavTooltip.style.width = `${ulDimensions.width}px`;
  quickNavTooltip.style.height = `${ulDimensions.height}px`;
}

function showTooltip() {
  quickNav.setAttribute('data-tooltip-visible', 'true');
}

function hideTooltip() {
  quickNav.setAttribute('data-tooltip-visible', 'false');
}

function showUl(ul) {
  if (ul) {
    ul.setAttribute('data-visible', 'true');
    activeUl = ul;
    const focusableElements = getFoocusableElements(ul);
    forEachElement(focusableElements, element => {
      element.setAttribute('tabindex', '0');
    });
  }
}

function hideUl(ul) {
  if (ul) {
    ul.setAttribute('data-visible', 'false');
    const focusableElements = getFoocusableElements(ul);
    forEachElement(focusableElements, element => {
      element.setAttribute('tabindex', '-1');
    });
  }
}

