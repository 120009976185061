import React from 'react';
import renderReactComponent from './utils/renderReactComponent';
import windowLoad from './utils/windowLoad';
//import './styles/index.scss';

import './components/Common';
import './components/Header';
import './components/Footer';
import './components/PageLayout';
import './components/Section';
import './components/Button';
import './components/Hero';
import './components/Accordion';
import './components/ImageButton';
import './components/IconGrid';
import './components/Icon';
import './components/Details';
import './components/Promo';
import './components/MediaText';
import './components/LinkText';
import './components/Partners';
import './components/LandingGrid';
import './components/Gallery';
import './components/FilterableColumns';
import './components/BlogPosts';
import './components/EventsCalendar';
import './components/IconText';
import './components/PerfectGym';

// React Components
import HeaderSearch from './components/Search';
import SearchResults from './components/Search/SearchResults';
import Subscribe from './components/Subscribe';
import Notifications from './components/Notifications';

renderReactComponent(<HeaderSearch />, '#HeaderSearch');
renderReactComponent(<SearchResults />, '#SearchResults');
renderReactComponent(<Subscribe />, '#Subscribe');
renderReactComponent(<Notifications />, '#Notifications');


// Set touch state
windowLoad(e => {

  function setTouch() {
    document.body.setAttribute('data-touch', 'true');
    document.removeEventListener('touchstart', setTouch);
  }

  document.addEventListener('touchstart', setTouch);

});
