
export default function windowResize(eventListener) {
  if (typeof eventListener !== 'function') return { detach: () => { } };

  let ticking = false;

  const resizeHandler = event => {

    if (ticking === false) {

      window.requestAnimationFrame(function () {
        eventListener(event, window.innerWidth, window.innerHeight);
        ticking = false;
      });

      ticking = true;
    }

  };

  window.addEventListener('resize', resizeHandler);

  return {
    detach: () => {
      return window.removeEventListener('resize', resizeHandler);
    }
  };

}
