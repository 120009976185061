import uniqid from 'uniqid';
import forEachElement from '../../utils/forEachElement';
import elementKeypress from '../../utils/elementKeypress';
import getFoocusableElements from '../../utils/getFoocusableElements';
import { openPanel } from '../Accordion';
import './styles.scss';

const mediaTextElements = document.querySelectorAll('.MediaText');

forEachElement(mediaTextElements, setupMediaText);

function focusButton(mediaText) {
  mediaText.querySelector('.MediaText__expand-button').focus();
}

function setupMediaText(mediaText) {

  const isExpandable = mediaText.classList.contains('MediaText--expandable');

  if (isExpandable) {

    const groupedMediaText = Array.prototype.slice.call(mediaText.parentNode.querySelectorAll('.MediaText--expandable'));
    const mediaTextIndex = groupedMediaText.indexOf(mediaText);

    const isFirst = mediaTextIndex === 0;
    const isLast = mediaTextIndex === groupedMediaText.length - 1;
    const firstMediaText = groupedMediaText[0];
    const lastMediaText = groupedMediaText[groupedMediaText.length - 1];
    const nextMediaText = groupedMediaText[mediaTextIndex + 1];
    const prevMediaText = groupedMediaText[mediaTextIndex - 1];

    const id = uniqid();
    const toggleId = `MediaText-toggle-${id}`;
    const expandId = `MediaText-expand-${id}`;
    const headingId = `MediaText-heading-${id}`;

    const heading = mediaText.querySelector('.MediaText__title');
    heading.setAttribute('id', headingId);
    const headingText = heading.innerText;

    const contentArea = mediaText.querySelector('.MediaText__content');

    const expandButton = document.createElement('button');
    expandButton.classList.add('Button');
    expandButton.classList.add('MediaText__expand-button');
    expandButton.innerHTML = mediaText.getAttribute('data-expandable-show');
    expandButton.setAttribute('id', toggleId);
    expandButton.setAttribute('aria-controls', expandId);
    expandButton.setAttribute('aria-expanded', 'false');
    expandButton.setAttribute('aria-label', `Expand '${headingText}'`);

    contentArea.appendChild(expandButton);

    const expandContainer = mediaText.querySelector('.MediaText__expand');
    const expandContainerInner = mediaText.querySelector('.MediaText__expand__inner');
  
    if (!expandContainer || !expandContainerInner) return;
  
    expandButton.setAttribute('data-active', 'false');
    expandButton.addEventListener('click', e => toggleExpandContainer(mediaText, expandButton, expandContainer, expandContainerInner));

    elementKeypress(expandButton, (e, key) => {

      if (key === 'Tab') {
        const isActive = expandButton.getAttribute('aria-expanded') === 'true';
        if (!e.shiftKey) {
          if (!isActive && nextMediaText) {
            e.preventDefault();
            focusButton(nextMediaText);
            return;
          }

          if (!isActive && isLast) {
            const nextElement = mediaText.nextElementSibling;
            if (!nextElement) {
              const parentSection = mediaText.closest('.Section');
              const nextSection = parentSection.nextElementSibling;
              if (nextSection) {
                const focusableElements = getFoocusableElements(nextSection);
                if (focusableElements.length > 0) {
                  e.preventDefault();
                  focusableElements[0].focus();
                }
              }
            }

          }

        } else {
          if (prevMediaText) {
            const isPrevActive = prevMediaText.getAttribute('aria-expanded') === 'true';
            if (!isPrevActive) {
              e.preventDefault();
              focusButton(prevMediaText);
            }
          }
        }
        return;
      }

      if (key === 'ArrowDown') {
        e.preventDefault();
        if (isLast) {
          focusButton(firstMediaText);
        } else {
          focusButton(nextMediaText);
        }
        return;
      }

      if (key === 'ArrowUp') {
        e.preventDefault();
        if (isFirst) {
          focusButton(lastMediaText);
        } else {
          focusButton(prevMediaText);
        }
        return;
      }

      if (key === 'Home') {
        e.preventDefault();
        focusButton(firstMediaText);
        return;
      }

      if (key === 'End') {
        e.preventDefault();
        focusButton(lastMediaText);
        return;
      }

    }, 'keydown');
  
    expandContainer.setAttribute('data-open', 'false');
    expandContainer.setAttribute('data-animating', 'false');
    expandContainer.setAttribute('id', expandId);
    expandContainer.setAttribute('role', 'region');
    expandContainer.setAttribute('aria-labelledby', headingId);
  
    expandContainer.addEventListener('transitionend', () => {
      if (expandContainer.getAttribute('data-open') === 'true') expandContainer.style.height = `auto`;
      expandContainer.setAttribute('data-animating', 'false');
    });

  }

}

function toggleExpandContainer(mediaText, expandButton, expandContainer, expandContainerInner) {
  const expandContainerOpen = expandContainer.getAttribute('data-open') === 'true';
  const expandContainerAnimating = expandContainer.getAttribute('data-animating') === 'true';

  if (!expandContainerInner || expandContainerAnimating) return;

  if (!expandContainerOpen) {
    openExpandContainer(mediaText, expandButton, expandContainer, expandContainerInner);
  } else {
    closeExpandContainer(mediaText, expandButton, expandContainer, expandContainerInner);
  }

}

function openExpandContainer(mediaText, expandButton, expandContainer, expandContainerInner) {
  const expandContainerInnerHeight = expandContainerInner.clientHeight;
  expandContainer.setAttribute('data-open', 'true');
  expandContainer.setAttribute('data-animating', 'true');
  expandContainer.style.height = `${expandContainerInnerHeight}px`;
  expandButton.innerHTML = mediaText.getAttribute('data-expandable-hide');
  expandButton.setAttribute('data-active', 'true');
  expandButton.setAttribute('aria-expanded', 'true');

  // open first accordion
  const accordions = expandContainerInner.querySelectorAll('.Accordion');
  if (accordions.length > 0) {
    forEachElement(accordions, accordion => {
      const toggle = accordion.querySelector('.Accordion__toggle:nth-child(1)');
      const toggleButton = toggle.querySelector('button');
      const panel = accordion.querySelector('.Accordion__panel:nth-child(2)');
      const panelInner = panel.querySelector('.Accordion__panel__inner');
      if (!toggle || !panel || !panelInner) return;
      openPanel(toggle, toggleButton, panel, panelInner);
      toggleButton.focus();
    });
  }


}

function closeExpandContainer(mediaText, expandButton, expandContainer, expandContainerInner) {
  const expandContainerInnerHeight = expandContainerInner.clientHeight;
  expandContainer.style.height = `${expandContainerInnerHeight}px`;
  expandContainer.setAttribute('data-open', 'false');
  expandContainer.setAttribute('data-animating', 'true');
  setTimeout(() => expandContainer.style.height = `0px`, 50);
  expandButton.innerHTML = mediaText.getAttribute('data-expandable-show');
  expandButton.setAttribute('data-active', 'false');
  expandButton.setAttribute('aria-expanded', 'false');
}
