import uniqid from 'uniqid';
import windowLoad from '../../utils/windowLoad';
import forEachElement from '../../utils/forEachElement';
import elementKeypress from '../../utils/elementKeypress';
import './styles.scss';

windowLoad(() => {
  const accordions = document.querySelectorAll('.Accordion');
  forEachElement(accordions, setupAccordion);
});

function focusButton(toggle) {
  toggle.querySelector('button').focus();
}

function setupAccordion(accordionElement) {

  const toggles = accordionElement.querySelectorAll('.Accordion__toggle');
  const panels = accordionElement.querySelectorAll('.Accordion__panel');

  forEachElement(toggles, (toggle, i) => {

    const firstToggle = toggles[0];
    const lastToggle = toggles[toggles.length - 1];
    const nextToggle = toggles[i + 1];
    const prevToggle = toggles[i - 1];
    const isFirstToggle = i === 0;
    const isLastToggle = i === toggles.length - 1;
    
    const panel = panels[i];
    const panelInner = panel.querySelector('.Accordion__panel__inner');

    const id = uniqid();
    const panelId = `Accordion-panel-${id}`;
    
    if (!panelInner) return;
    
    const toggleText = toggle.innerHTML;
    const toggleButtonId = `Accordion-toggle-${id}`;
    const toggleButton = document.createElement('button');
    toggleButton.innerHTML = toggleText;
    toggle.innerHTML = '';
    toggle.appendChild(toggleButton);

    toggleButton.setAttribute('id', toggleButtonId);
    toggleButton.setAttribute('aria-controls', panelId);

    toggleButton.addEventListener('click', e => togglePanel(toggle, toggleButton, panel, panelInner));
    toggle.setAttribute('data-active', 'false');

    const toggleId = toggle.getAttribute('id');
    const urlHash = window.location.hash;
    if (toggleId && urlHash) {
      if (urlHash === `#${toggleId}`) {
        togglePanel(toggle, toggleButton, panel, panelInner)
      }
    }

    if (panel.getAttribute('data-open') !== 'true') {
      panel.setAttribute('data-open', 'false');
      toggleButton.setAttribute('aria-expanded', 'false');
    } else {
      toggle.setAttribute('data-active', 'true');
      toggleButton.setAttribute('aria-expanded', 'true');
    }

    panel.setAttribute('id', panelId);
    panel.setAttribute('data-animating', 'false');
    panel.setAttribute('role', 'region');
    panel.setAttribute('aria-labelledby', toggleButtonId);

    panel.addEventListener('transitionend', () => {
      if (panel.getAttribute('data-open') === 'true') panel.style.height = `auto`;
      panel.setAttribute('data-animating', 'false');
    });

    elementKeypress(toggleButton, (e, key) => {

      if (key === 'Tab') {
        const isActive = toggle.getAttribute('data-active') === 'true';
        if (!e.shiftKey) {
          if (!isActive && nextToggle) {
            e.preventDefault();
            focusButton(nextToggle);
          }
        } else {
          if (prevToggle) {
            const isPrevActive = prevToggle.getAttribute('data-active') === 'true';
            if (!isPrevActive) {
              e.preventDefault();
              focusButton(prevToggle);
            }
          }
        }
        return;
      }

      if (key === 'ArrowDown') {
        e.preventDefault();
        if (isLastToggle) {
          focusButton(firstToggle);
        } else {
          focusButton(nextToggle);
        }
        return;
      }

      if (key === 'ArrowUp') {
        e.preventDefault();
        if (isFirstToggle) {
          focusButton(lastToggle);
        } else {
          focusButton(prevToggle);
        }
        return;
      }

      if (key === 'Home') {
        e.preventDefault();
        focusButton(firstToggle);
        return;
      }

      if (key === 'End') {
        e.preventDefault();
        focusButton(lastToggle);
        return;
      }

    }, 'keydown');

   });

}

function togglePanel(toggle, toggleButton, panel, panelInner) {
  const panelOpen = panel.getAttribute('data-open') === 'true';
  const panelAnimating = panel.getAttribute('data-animating') === 'true';
  
  if (panelAnimating) return;

  if (!panelOpen) {
    openPanel(toggle, toggleButton, panel, panelInner);
  } else {
    closePanel(toggle, toggleButton, panel, panelInner);
  }

}

export function openPanel(toggle, toggleButton, panel, panelInner) {
  const panelInnerHeight = panelInner.clientHeight;
  panel.setAttribute('data-open', 'true');
  panel.setAttribute('data-animating', 'true');
  panel.style.height = `${panelInnerHeight}px`;
  toggle.setAttribute('data-active', 'true');
  toggleButton.setAttribute('aria-expanded', 'true');
}

export function closePanel(toggle, toggleButton, panel, panelInner) {
  const panelInnerHeight = panelInner.clientHeight;
  panel.style.height = `${panelInnerHeight}px`;
  panel.setAttribute('data-open', 'false');
  panel.setAttribute('data-animating', 'true');
  setTimeout(() => panel.style.height = `0px`, 50);
  toggle.setAttribute('data-active', 'false');
  toggleButton.setAttribute('aria-expanded', 'false');
}
