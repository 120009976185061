
const defaultValues = {
  position: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    centerX: 0,
    centerY: 0,
    domRect: null,
  },
  offset: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    computedStyle: null,
  },
};

export default function getElementPosition(element, offsetFrom = null) {

  if (!element) return defaultValues;

  const elementBounding = element.getBoundingClientRect();
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const position = {
    left: elementBounding.left,
    top: elementBounding.top,
    right: windowWidth - elementBounding.right,
    bottom: windowHeight - elementBounding.bottom,
    centerX: elementBounding.left + (elementBounding.width / 2),
    centerY: elementBounding.top + (elementBounding.height / 2),
    domRect: elementBounding,
  };

  let offset = null;
  if (offsetFrom) {
    const offsetFromPosition = getElementPosition(offsetFrom);
    offset = {
      left: position.left - offsetFromPosition.position.left,
      top: position.top - offsetFromPosition.position.top,
      right: position.right - offsetFromPosition.position.right,
      bottom: position.bottom - offsetFromPosition.position.bottom,
    };
  }

  return {
    position,
    offset,
  };
}
