import windowLoad from '../../utils/windowLoad';
import getQueryStringParam from '../../utils/getQueryStringParam';
import './styles.scss';

windowLoad(() => {
  const perfectGymElement = document.querySelector('.PerfectGym');
  if (!perfectGymElement) return;
  
  const screen = perfectGymElement.getAttribute('data-screen');
  const useUAT = perfectGymElement.getAttribute('data-use-uat');
  
  let perfectGymDomain = 'https://melbournesportscentres.perfectgym.com.au';
  if (useUAT === 'true') {
    perfectGymDomain = 'https://melbournesportscentresuat.perfectgym.com.au';
  }

  if (screen === 'Ecommerce') {

    // PerfectGym ClientPortal2 SDK
    const perfectGymJs = document.createElement('script');
    perfectGymJs.setAttribute('src', `${perfectGymDomain}/ClientPortal2/sdk`);
    document.body.appendChild(perfectGymJs);

    perfectGymJs.onload = () => {
      setupClientPortalEcommerce(perfectGymElement);
    };

  } else {

    // PerfectGym stylesheet
    const perfectGymStylesheet = document.createElement('link');
    perfectGymStylesheet.setAttribute('rel', 'stylesheet');
    perfectGymStylesheet.setAttribute('href', 'https://cdn.jsdelivr.net/npm/@perfectgym/client-portal@latest/dist/ClientPortal.css');
    document.head.appendChild(perfectGymStylesheet);

    // iFrameResizer JS
    const iFrameResizerJs = document.createElement('script');
    iFrameResizerJs.setAttribute('src', 'https://cdn.jsdelivr.net/npm/iframe-resizer@latest/js/iframeResizer.min.js');
    document.body.appendChild(iFrameResizerJs);

    iFrameResizerJs.onload = () => {

      // PerfectGym JS
      const perfectGymJs = document.createElement('script');
      perfectGymJs.setAttribute('src', 'https://cdn.jsdelivr.net/npm/@perfectgym/client-portal@latest/dist/ClientPortal.min.js');
      document.body.appendChild(perfectGymJs);

      perfectGymJs.onload = () => {
        setupClientPortalEmbed(perfectGymElement, perfectGymDomain);
      };

    };

  }

});

function setupClientPortalEmbed(perfectGymElement, perfectGymDomain) {
  const PerfectGym = window.PerfectGym;
  if (!perfectGymElement || !PerfectGym) return;

  const screen = perfectGymElement.getAttribute('data-screen');

  let perfectGymOptions = {
    url: getPerfectGymUrl({}, perfectGymDomain),
    defaultState: PerfectGym.ClientPortal.State[screen],
  };

  // navigation
  if (screen !== 'Login' && screen !== 'Registration') {
    perfectGymOptions.navigation = {
      hide: JSON.parse(perfectGymElement.getAttribute('data-nav-hide')),
      logo: JSON.parse(perfectGymElement.getAttribute('data-nav-logo')),
    };
  }

  // loginPage
  if (screen === 'Login') {
    perfectGymOptions.loginPage = {
      navbar: JSON.parse(perfectGymElement.getAttribute('data-login-navbar')),
      logo: JSON.parse(perfectGymElement.getAttribute('data-login-logo')),
    };
  }

  // registration
  if (screen === 'Registration') {
    perfectGymOptions.registration = {
      logo: JSON.parse(perfectGymElement.getAttribute('data-registration-logo')),
    };
  }

  // calendarPage
  if (screen === 'Classes' || screen === 'ClassesList') {
    perfectGymOptions.calendarPage = {
      hideBookingIfNotLogged: JSON.parse(perfectGymElement.getAttribute('data-calendar-hide-booking')),
    };

    const categoryId = getQueryStringParam('categoryId', perfectGymElement.getAttribute('data-calendar-classes-category'));
    if (categoryId) {
      perfectGymOptions.forceUrl = getPerfectGymUrl({ screen, categoryId }, perfectGymDomain);
    }

  }

  const CP = new PerfectGym.ClientPortal(perfectGymElement, perfectGymOptions);

  return CP;
}

function setupClientPortalEcommerce(perfectGymElement) {
  const PerfectGym = window.PerfectGym;
  if (!perfectGymElement || !PerfectGym) return;

  const id = perfectGymElement.getAttribute('id');
  const flowId = getQueryStringParam('flowId', perfectGymElement.getAttribute('data-flow-id'));

  const ecommConfig = {
    flowId,
    element: `#${id}`
  };

  PerfectGym.ECommerce.init(ecommConfig);
}

function getPerfectGymUrl({ screen, categoryId, zoneId, showOnlyCourses = false }, perfectGymDomain) {
  let url = `${perfectGymDomain}/ClientPortal2/`;
  if (screen !== 'Classes' && screen !== 'ClassesList') return url;
  
  let params = [];
  url = `${url}#/Classes/1/${screen === 'ClassesList' ? 'List' : 'Calendar'}`;

  if (categoryId) params.push(['categoryId', categoryId]);
  if (zoneId) params.push(['zoneId', zoneId]);

  params.push(['showOnlyCourses', JSON.stringify(showOnlyCourses)]);

  if (params.length > 0) {
    url = `${url}?${params.map(param => param.join('=')).join('&')}`;
  }

  return url;
}
