
export default function windowSize(includeScrollbars = true) {
  if (includeScrollbars) {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  } else {
    const html = document.querySelector('html');
    return {
      width: html.clientWidth,
      height: html.clientHeight,
    };
  }  
}
