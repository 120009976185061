import windowScroll from '../../utils/windowScroll';

// window scroll
document.body.setAttribute('data-header-condensed', 'false');
windowScroll(handleWindowScroll);
checkScrollPosition(window.scrollY);

function handleWindowScroll(w, scrollX, scrollY) {
  checkScrollPosition(scrollY);
}

function checkScrollPosition(scrollY) {
  const bodyPaddingTop = numFromPx(window.getComputedStyle(document.body).paddingTop);

  if (scrollY >= bodyPaddingTop * 1.5) {
    document.body.setAttribute('data-header-condensed', 'true');
  } else {
    document.body.setAttribute('data-header-condensed', 'false');
  }
}

function numFromPx(style) {
  return Number(style.replace(/px/, ''));
}
