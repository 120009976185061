import windowLoad from '../../utils/windowLoad';
import forEachElement from '../../utils/forEachElement';
import './styles.scss';

windowLoad(() => {
  const filterableColumns = document.querySelectorAll('.FilterableColumns');
  forEachElement(filterableColumns, setupFilterableColumns);
});

function setupFilterableColumns(filterableColumnsElement) {

  const titleElement = filterableColumnsElement.querySelector('.FilterableColumns__title');
  const title = titleElement.innerHTML;
  const buttons = filterableColumnsElement.querySelectorAll('.wp-block-button__link');
  const columns = filterableColumnsElement.querySelectorAll('.FilterableColumns__column');

  forEachElement(buttons, button => {
    button.setAttribute('data-active', 'false');
  });

  forEachElement(columns, column => {
    column.setAttribute('data-show', 'true');
  });

  const firstButton = buttons[0];
  const allButton = document.createElement('div');
        allButton.classList.add('wp-block-button');
  const allButtonLink = document.createElement('a');
        allButtonLink.classList.add('wp-block-button__link');
        allButtonLink.innerHTML = 'All';
        allButtonLink.setAttribute('data-active', 'true');
        allButton.appendChild(allButtonLink);
  
  firstButton.parentNode.parentNode.insertBefore(allButton, firstButton.parentNode);

  function handleButtonClick(button, term) {

    buttons.forEach(button => button.setAttribute('data-active', 'false'));
    allButtonLink.setAttribute('data-active', 'false');
    button.setAttribute('data-active', 'true');

    columns.forEach(column => {

      if (term === 'All') {
        column.setAttribute('data-show', 'true');
        titleElement.innerHTML = title;
        return;
      }

      const columnTerms = column.getAttribute('data-terms').split(',');

      if (columnTerms.indexOf(term) > -1) {
        column.setAttribute('data-show', 'true');
      } else {
        column.setAttribute('data-show', 'false');
      }

      titleElement.innerHTML = `${title} - ${term}`;

    });

  }

  buttons.forEach(button => {
    const term = button.innerHTML;
    button.addEventListener('click', () => handleButtonClick(button, term));
  });

  allButtonLink.addEventListener('click', () => handleButtonClick(allButtonLink, 'All'));

}
