
export default function elementKeypress(element, eventListener, eventType = 'keydown') {
  if (typeof eventListener !== 'function') return { detach: () => { } };

  const keypressHandler = event => {
    if (event.target !== element) return;
    eventListener(event, event.key);
  };

  document.addEventListener(eventType, keypressHandler);

  return {
    detach: () => {
      return document.removeEventListener(eventType, keypressHandler);
    }
  };

}
