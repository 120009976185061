import React, { useState } from 'react';
import Form, { FormGroup, FormInputText, FormCheckbox, FormSubmit } from '../Form';
import './styles.scss';

function postSubscribeForm(data) {
  return fetch('/wp-json/msc/v1/subscribe', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
}

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function mobileIsValid(mobile) {
  return /^04\d{8}$/g.test(mobile.replace(/\s/g,''));
}

const Subscribe = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscribeEmail, setSubscribeEmail] = useState(false);
  const [subscribeMobile, setSubscribeMobile] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [postErrors, setPostErrors] = useState([]);

  const resetForm = () => {
    setSubscribeEmail(true);
    setSubscribeMobile(false);
    setFirstName('');
    setLastName('');
    setEmail('');
    setMobile('');
  };

  const handleSubmit = e => {
    e.preventDefault();
    setPostErrors([]);

    setIsSubmitting(true);

    const subscribeTo = [];
    if (subscribeEmail) subscribeTo.push('eNewsletter');
    if (subscribeMobile) subscribeTo.push('SMS');

    const postData = {
      subscribeTo,
      firstName,
      lastName,
      email,
      mobile: mobile.replace(/\s/g,''),
    };

    postSubscribeForm(postData)
      .then(response => response.json())
      .then(result => {
        setIsSubmitting(false);
        const { success, errors } = result;

        if (success) {
          resetForm();
          setSuccessMessage('Thank you for subscribing');
          setTimeout(() => setSuccessMessage(null), 5000);
        } else {
          setPostErrors(Object.values(errors));
        }

      })
      .catch(error => {
        console.error('Subscribe error:', error);
        setPostErrors(['An error occured, please try again in a few moments']);
        setIsSubmitting(false);
      });

  };

  const allValid = () => {
    if (!subscribeEmail && !subscribeMobile) return false;

    if (firstName.trim().length === 0) return false;
    if (lastName.trim().length === 0) return false;

    if (subscribeEmail && !emailIsValid(email)) return false;
    if (subscribeMobile && !mobileIsValid(mobile)) return false;

    return true;
  };

  return (
    <div className="Subscribe">
      <h2>Subscribe to receive our:</h2>
      {successMessage && <div className="Subscribe__success">{successMessage}</div>}
      {postErrors && (
        <ul className="Subscribe__errors">
          {postErrors.map((error, i) => <li key={i}>{error}</li>)}
        </ul>
      )}
      <Form className="Subscribe__form" onSubmit={handleSubmit}>
        <div className="Subscribe__options">
          <FormCheckbox
            id="subscribe-e-newsletter"
            label="e-newsletter"
            checked={subscribeEmail}
            onChange={e => setSubscribeEmail(e.target.checked)}
          />
          <FormCheckbox
            id="subscribe-sms"
            label="Alerts"
            checked={subscribeMobile}
            onChange={e => setSubscribeMobile(e.target.checked)}
          />
        </div>
        <FormGroup>
          <FormInputText
            id="subscribe-first-name"
            label="First Name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <FormInputText
            id="subscribe-last-name"
            label="Last Name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </FormGroup>
        {subscribeEmail && (
          <FormGroup>
            <FormInputText
              type="email"
              id="subscribe-email"
              label="Email Address"
              value={email}
              onChange={e => setEmail(e.target.value)}
              />
          </FormGroup>
        )}
        {subscribeMobile && (
          <FormGroup>
            <FormInputText
              id="subscribe-mobile"
              label="Mobile Phone Number"
              value={mobile}
              onChange={e => setMobile(e.target.value)}
            />
          </FormGroup>
        )}
        <FormSubmit>
          <button className="Button Button--white" disabled={!allValid() || isSubmitting}>Subscribe</button>
        </FormSubmit>
      </Form>
    </div>
  );
};

export default Subscribe;
