import windowLoad from '../../utils/windowLoad';
import forEachElement from '../../utils/forEachElement';
import documentKeypress from '../../utils/documentKeypress';
import getFoocusableElements from '../../utils/getFoocusableElements';
import trapFocus from '../../utils/trapFocus';
import { setBodyOverflowHidden } from '../Common';
import './headerScroll';
import './mainNav';
import './quickNav';

import './styles.scss';

const body = document.body;

windowLoad(e => {
  const menuButton = document.querySelector('.Header__menu-button');
  menuButton.addEventListener('click', handleMenuButtonClick);
  disableSearchFocus();

  documentKeypress((e, key) => {
    if (key === 'Escape' && searchOpen()) {
      hideSearch();
      document.querySelector('.Header__search__toggle').focus();
    }
  }, 'keyup');

});

function handleMenuButtonClick() {

  if (searchOpen()) {
    hideSearch();
  } else if (navOpen()) {
    hideNav();
  } else {
    showNav();
  }

}

export function getHeaderElement() {
  return document.getElementById('Header');
}

export function showHeaderOverlay() {
  body.setAttribute('data-show-overlay', 'true');
  setBodyOverflowHidden(true);
}

export function hideHeaderOverlay() {
  body.setAttribute('data-show-overlay', 'false');
  setBodyOverflowHidden(false);
}

export function navOpen() {
  return body.getAttribute('data-nav-open') === 'true';
}

export function showNav() {
  body.setAttribute('data-nav-open', 'true');
  showHeaderOverlay();
}

export function hideNav(hideOverlay = true) {
  body.setAttribute('data-nav-open', 'false');
  if (hideOverlay) hideHeaderOverlay();
}

let searchFocusTrap = null;
export function trapSearchFocus() {
  searchFocusTrap = trapFocus(document.querySelector('.Header__search'));
}

export function untrapSearchFocus() {
  if (searchFocusTrap) {
    searchFocusTrap.detach();
    searchFocusTrap = null;
  }
}

export function searchOpen() {
  return body.getAttribute('data-search-open') === 'true';
}

const showSearchEvent = new Event('showSearch');

export function showSearch() {
  document.dispatchEvent(showSearchEvent);
  body.setAttribute('data-search-open', 'true');
  showHeaderOverlay();
  hideNav(false);
  enableSearchFocus();
  trapSearchFocus();
}

const hideSearchEvent = new Event('hideSearch');

export function hideSearch(hideOverlay = true) {
  document.dispatchEvent(hideSearchEvent);
  body.setAttribute('data-search-open', 'false');
  if (hideOverlay) hideHeaderOverlay();
  untrapSearchFocus();
  disableSearchFocus();
}

export function disableSearchFocus() {
  const focusableElements = getSearchFocusableElements();
  if (!focusableElements) return;
  forEachElement(focusableElements, element => {
    element.setAttribute('tabindex', '-1');
  });
}

export function enableSearchFocus() {
  const focusableElements = getSearchFocusableElements();
  if (!focusableElements) return;
  forEachElement(focusableElements, element => {
    element.setAttribute('tabindex', '0');
  });
}

export function getSearchFocusableElements() {
  const searchElement = document.querySelector('.Header__search__dropdown');
  return getFoocusableElements(searchElement);
}
