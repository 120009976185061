import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Fuse from 'fuse.js';
import { searchOpen, showSearch } from '../Header';
import SearchIcon from './SearchIcon';
import './styles.scss';
import documentKeypress from '../../utils/documentKeypress';

function fetchSearchConfig() {
  return fetch('/wp-json/msc/v1/search-config').then(response => response.json());
}

const searchResultsElement = document.getElementById('HeaderSearch-results');

const HeaderSearch = (props) => {
  const [fuseData, setFuseData] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchToggle = useRef();
  const [searchToggleKeypress, setSearchToggleKeypress] = useState(null);

  useEffect(() => {

    fetchSearchConfig().then(response => {
      setSuggestions(response.suggestions);

      const fuseTerms = [...response.suggestions, ...response.search_terms];

      const fuse = new Fuse(fuseTerms, {
        threshold: 0.4,
        keys: [
          {
            name: 'search_term',
            weight: 0.9
          },
          {
            name: 'page_title',
            weight: 0.5
          },
          {
            name: 'text',
            weight: 0.4
          },
        ]
      });

      setFuseData(fuse);

    });

    document.addEventListener('showSearch', () => setIsSearchOpen(true));
    document.addEventListener('hideSearch', () => setIsSearchOpen(false));

  }, []);

  useEffect(() => {
    if (!searchToggle.current) return;
    setSearchToggleKeypress(documentKeypress((e, key) => {
      if (key === 'Enter' && e.target === searchToggle.current) {
        handleOpenSearch();
      }
    }));
    return () => {
      if (searchToggleKeypress) searchToggleKeypress.detach();
    }
  }, []);

  const handleOpenSearch = () => {
    if (searchOpen()) return;
    showSearch();
    setIsSearchOpen(true);
  };

  const handleSearch = query => {
    setSearchQuery(query);

    if (fuseData && query.trim().length >= 3) {
      setIsSearching(true);

      const fuseResults = fuseData.search(query);

      if (fuseResults.length > 0) {
        setSearchResults(fuseResults.map(result => {
          const { id, page_title, search_term, url } = result.item;
          const text = result.item.text ? result.item.text : (page_title ? page_title : search_term);
          return {
            id, text, url
          };
        }));
      } else {
        setSearchResults([]);
      }


    } else {
      setIsSearching(false);
      setSearchResults([]);
    }

  };

  return (
    <React.Fragment>
      <div className="Header__search__toggle" onClick={handleOpenSearch} tabIndex={isSearchOpen ? '-1' : '0'} ref={searchToggle} aria-label="Open Search">
        <HeaderSearchEntry searchQuery={searchQuery} handleSearch={handleSearch} open={isSearchOpen} section="toggle" />
      </div>
      <HeaderSearchResults isSearching={isSearching} results={isSearching ? searchResults : suggestions} handleSearch={handleSearch} searchQuery={searchQuery} />
    </React.Fragment>
  );
};

export default HeaderSearch;

const HeaderSearchEntry = (props) => {
  const { searchQuery, handleSearch, open, section } = props;
  const searchField = useRef();
  const fieldId = `${section}-search-fieled`;

  const focusSearchField = () => {
    if (open && searchField.current) searchField.current.focus();
  };

  useEffect(() => {
    if (open && searchField.current) searchField.current.focus();
  }, [open]);
  
  return (
    <div className="HeaderSearch__entry" onClick={focusSearchField}>
      <div className="HeaderSearch__entry__query">
        <span aria-hidden="true">I'd like to</span>
        <div className="HeaderSearch__entry__input">
          <label htmlFor={fieldId} className="sr-only">Enter search term:</label>
          <input type="text" id={fieldId} value={searchQuery} placeholder="..." onChange={e => handleSearch(e.target.value)} disabled={!open} ref={searchField} />
        </div>
      </div>
      <SearchIcon />
    </div>
  );
};

const HeaderSearchResults = (props) => {
  const { isSearching, searchQuery, handleSearch } = props;
  const searchLink = `/search/?query=${encodeURIComponent(searchQuery)}`;
  let results = [...props.results];
  if (isSearching) results.splice(10); // limit to 10 results
  return ReactDOM.createPortal((
    <div className="HeaderSearch__results">
      <HeaderSearchEntry searchQuery={searchQuery} handleSearch={handleSearch} section="results" />
      <div className="HeaderSearch__results__title">Suggestions</div>
      {(isSearching && results.length === 0) && (
        <React.Fragment>
          <p className="HeaderSearch__results__note">No suggestions to show for your query - <strong>{searchQuery}</strong>.</p>
          <p>Click <a href={searchLink}>Show All Results</a> to do a full site search.</p>
        </React.Fragment>
      )}
      <ul className="HeaderSearch__results__list">
        {results.map(result => (
          <li key={result.id}>
            <a href={result.url} tabIndex={searchOpen() ? '0' : '-1'}>{result.text}</a>
          </li>
        ))}
      </ul>
      {isSearching && (
        <div className="HeaderSearch__results__button">
          <a href={searchLink} className="Button" tabIndex={searchOpen() ? '0' : '-1'}>Show All Results</a>
        </div>
      )}
    </div>
  ), searchResultsElement);
};
