import getFoocusableElements from './getFoocusableElements';
import forEachElement from './forEachElement';
import getElementIndex from './getElementIndex';

export default function trapFocus(parent) {
  if (!parent) return { detach: () => { } };
  const focusableElements = getFoocusableElements(parent);

  const handleKeydown = e => {
    const focusableElements = getFoocusableElements(parent);
    const target = e.target;
    const targetIndex = getElementIndex(focusableElements, target);
    const elementsLength = focusableElements.length;
    if (e.shiftKey && e.key === 'Tab') {
      if (targetIndex === 0) {
        focusableElements[elementsLength - 1].focus();
        e.preventDefault();
      }
    } else if (e.key === 'Tab') {
      if (targetIndex === elementsLength - 1) {
        focusableElements[0].focus();
        e.preventDefault();
      }
    }
  };

  forEachElement(focusableElements, element => {
    element.addEventListener('keydown', handleKeydown);
  });

  return { detach: () => {
    forEachElement(focusableElements, element => {
      element.removeEventListener('keydown', handleKeydown);
    });
  }};
}
