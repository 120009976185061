import React, { useState } from 'react';
import clsx from 'clsx';
import windowLoad from '../../utils/windowLoad';
import forEachElement from '../../utils/forEachElement';
import './styles.scss';

const Form = (props => {
  const { children, className, ...otherProps } = props;
  return (
    <form className={clsx('Form', className)} {...otherProps}>
      {children}
    </form>
  );
});

export default Form;

export const FormGroup = (props => {
  const { children, className, ...otherProps } = props;
  return (
    <div className={clsx('Form__group', className)} {...otherProps}>
      {children}
    </div>
  );
});

export const FormInputText = (props => {
  const { type, id, label, value, onChange, ...otherProps } = props;
  const inputType = type || 'text';
  const [focused, setFocused] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleFocus = e => {
    setFocused(true);
  };

  const handleBlur = e => {
    if (!touched) setTouched(true);
    setFocused(false);
  };

  const classes = clsx(
    'Form__input',
    `Form__input--${inputType}`,
    focused && `Form__input--${inputType}--focused`,
    touched && `Form__input--${inputType}--touched`,
    String(value).trim().length > 0 && `Form__input--${inputType}--has-value`,
  );

  return (
    <div className={classes} {...otherProps}>
      <input type={inputType} id={id} value={value} onChange={onChange} onFocus={handleFocus} onBlur={handleBlur} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
});

export const FormCheckbox = (props => {
  const { id, label, checked, onChange, ...otherProps } = props;
  const inputType = 'checkbox';
  const [focused, setFocused] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleFocus = e => {
    setFocused(true);
  };

  const handleBlur = e => {
    if (!touched) setTouched(true);
    setFocused(false);
  };

  const classes = clsx(
    'Form__checkbox',
    `Form__checkbox--${inputType}`,
    focused && `Form__checkbox--${inputType}--focused`,
    touched && `Form__checkbox--${inputType}--touched`,
  );

  return (
    <div className={classes} {...otherProps}>
      <label htmlFor={id}>
        <input type="checkbox" value="1" checked={checked && 'checked'} id={id} onChange={onChange} onFocus={handleFocus} onBlur={handleBlur} />
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.4 20.4">
            <path d="M24.9.5c-.6-.6-1.7-.6-2.3 0L8.1 15l-4.7-4.7c-.7-.7-1.9-.8-2.7-.1-.8.7-.9 2-.2 2.8l6 6.7.2.2c.9.8 2.3.7 3.1-.2L25 2.7c.6-.6.5-1.6-.1-2.2z" />
          </svg>
        </span>
        {label}
      </label>
    </div>
  );
});

export const FormSubmit = (props => {
  const { children, className, ...otherProps } = props;
  return (
    <div className={clsx('Form__submit', className)} {...otherProps}>
      {children}
    </div>
  );
});




windowLoad(() => {
  const formFields = document.querySelectorAll('.frm_form_field input, .frm_form_field textarea, .frm_form_field select');

  forEachElement(formFields, setupField);

  const submitButton = document.querySelector('.frm_button_submit');
  if (submitButton) submitButton.classList.add('Button');

});

function setupField(field) {  
  if (field.type === 'hidden') return;

  const fieldParent = field.parentElement;

  field.addEventListener('focus', () => {
    fieldParent.classList.add('frm_form_field--focused');
  });

  field.addEventListener('blur', () => {
    fieldParent.classList.remove('frm_form_field--focused');
  });

  field.addEventListener('change', e => {
    const fieldValue = e.target.value;

    if (String(fieldValue).trim().length > 0) {
      fieldParent.classList.add('frm_form_field--has-value');
    } else {
      fieldParent.classList.remove('frm_form_field--has-value');
    }

  });

  const fieldValue = field.value;
  if (String(fieldValue).trim().length > 0) {
    fieldParent.classList.add('frm_form_field--has-value');
  } else {
    fieldParent.classList.remove('frm_form_field--has-value');
  }

  const span = document.createElement('span');

  if (field.type === 'checkbox' || field.type === 'radio') {
    fieldParent.insertBefore(span, field.nextSibling);
  }

  if (field.tagName === 'SELECT') {
    fieldParent.appendChild(span);
  }

}
