
export default function documentKeypress(eventListener, eventType = 'keypress') {
  if (typeof eventListener !== 'function') return { detach: () => { } };

  const keypressHandler = event => {
    eventListener(event, event.key);
  };

  document.addEventListener(eventType, keypressHandler);

  return {
    detach: () => {
      return document.removeEventListener(eventType, keypressHandler);
    }
  };

}
