import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import './styles.scss';

function fetchNotifications() {
  const currentDate = new Date()
  const currentDateYmd = `${currentDate.getFullYear()}${String(currentDate.getMonth() + 1).padStart(2, '0')}${String(currentDate.getDate()).padStart(2, '0')}`;
  return fetch(`/wp-json/msc/v1/notifications?date=${currentDateYmd}&site=${window.mscSiteID}`).then(response => response.json());
}

function getBannerNotifications(notifications) {
  if (!notifications) return [];
  return notifications.filter(notification => notification.position === 'banner');
};

const mscStorage = window.localStorage;

function getDismissedNotifications() {
  const dismissedNotifications = mscStorage.getItem('msc-dismissed-notifications');
  return dismissedNotifications ? JSON.parse(dismissedNotifications) : [];
}

function setDismissedNotifications(notificationIds) {
  mscStorage.setItem('msc-dismissed-notifications', JSON.stringify(notificationIds));
}

const Notifications = (props) => {
  const [notifications, setNotifications] = useState([]);

  const bannerNotifications = notifications.filter(notification => notification.position === 'banner');
  const sideNotifications = notifications.filter(notification => notification.position === 'side');
  const popupNotifications = notifications.filter(notification => notification.position === 'popup');

  const dismissNotification = (id) => {
    let dismissedNotifications = getDismissedNotifications();
    const newNotifications = notifications.filter(notification => notification.id !== id);
    setNotifications(newNotifications);
    if (getBannerNotifications(newNotifications).length === 0) document.body.setAttribute('data-banner-notification', 'false');
    dismissedNotifications.push(id);
    setDismissedNotifications(dismissedNotifications);
  };

  const dissmissAllNotifications = () => {
    notifications.forEach(notification => {
      dismissNotification(notification.id);
    });
  };

  useEffect(() => {

    fetchNotifications().then(notifications => {

      if (window.pageNotifications) {
        notifications = [ ...window.pageNotifications, ...notifications ];
      }

      let dismissedNotifications = getDismissedNotifications();
      const newNotifications = notifications.filter(notification => dismissedNotifications.indexOf(notification.id) === -1);

      setNotifications(newNotifications);
      if (getBannerNotifications(newNotifications).length > 0) {
        document.body.setAttribute('data-banner-notification', 'true');
      }
    });

  }, []);

  return (
    <div className="Notifications">
      <h2 className="sr-only">Notifications</h2>
      <ul className="Notifications__banner">
        {bannerNotifications.map(notification => (
          <Notification {...notification} key={notification.id} dismissNotification={dismissNotification} />
        ))}
      </ul>
      <ul className="Notifications__side">
        {sideNotifications.map(notification => (
          <Notification {...notification} key={notification.id} dismissNotification={dismissNotification} />
        ))}
      </ul>
      <div className="Notifications__popups" data-visible={popupNotifications.length > 0}>
        {popupNotifications.length > 0 && (
          <ul>
            {popupNotifications.map(notification => (
              <Notification {...notification} key={notification.id} dismissNotification={dismissNotification} />
            ))}
          </ul>
        )}
      </div>
      <button className="sr-only" onClick={e => dissmissAllNotifications()}>Dissmiss All Notifications</button>
    </div>
  );
};

export default Notifications;

const Notification = (props) => {
  const { id, position, color, title, text, linkUrl, linkText, dismissNotification, dismissible } = props;
  return (
    <li className={clsx('Notification', `Notification--${position}`, `Notification--${position}--${color}`)}>
      <div className={`Notification--${position}__inner`}>
        <h3 className="Notification__title">{title}</h3>
        <p className="Notification__text">{text}</p>
        {(linkText && linkUrl) && (
          <a className="Notification__link LinkText" href={linkUrl}>
            {linkText}
          </a>
        )}
        {dismissible && (
          <button className={clsx('Notification__close', `Notification--${position}__close`)} onClick={e => dismissNotification(Number(id))}>Close</button>
        )}
      </div>
    </li>
  );
};
