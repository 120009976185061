import windowLoad from '../../utils/windowLoad';
import forEachElement from '../../utils/forEachElement';
import './styles.scss';

windowLoad(() => {

  const heros = document.querySelectorAll('.Hero');

  forEachElement(heros, hero => {

    const isStandardHero = hero.classList.contains('Hero--standard') || hero.classList.contains('Hero--blog');

    if (isStandardHero) {
      // add breadcrumbs

      const heroContent = hero.querySelector('.Hero__content');

      const breadcrumbs = document.createElement('div');
      breadcrumbs.classList.add('Hero__breadcrumbs');

      const breadcrumbsUl = document.createElement('ul');

      const homeLink = document.createElement('a');
      homeLink.classList.add('Hero__breadcrumbs__home');
      homeLink.setAttribute('href', '/');
      homeLink.innerHTML = 'Home';

      const siteLink = document.createElement('a');
      siteLink.classList.add('Hero__breadcrumbs__site');
      siteLink.setAttribute('href', window.mscSiteUrl);
      siteLink.innerHTML = window.mscSiteTitle;

      const pageLink = document.createElement('span');
      pageLink.classList.add('Hero__breadcrumbs__page');
      pageLink.innerHTML = window.mscPageTitle;

      function li(link) {
        const liElement = document.createElement('li');
        liElement.appendChild(link);
        return liElement;
      }

      breadcrumbsUl.appendChild(li(homeLink));
      breadcrumbsUl.appendChild(li(siteLink));
      breadcrumbsUl.appendChild(li(pageLink));

      breadcrumbs.appendChild(breadcrumbsUl);

      heroContent.insertBefore(breadcrumbs, heroContent.firstChild);

    }

    // set tageline
    const hasTagline = hero.getAttribute('data-tagline') === 'true';
    if (hasTagline) {
      const heading = hero.querySelector('.Hero__title');
      if (heading) {
        const headingText = heading.innerHTML;
        heading.innerHTML = '';
        String(headingText).trim().split(' ').forEach(word => {
          const span = document.createElement('span');
          const space = document.createTextNode("\u00A0");
          span.innerHTML = word;
          heading.appendChild(span);
          heading.appendChild(space);
        });
      }
    }

    // setup video
    const mediaContainer = hero.querySelector('.Hero__media');
    const video = mediaContainer.querySelector('video');
    if (!video) return;
    
    const poster = video.getAttribute('poster');
    if (poster) {
      mediaContainer.style.backgroundImage = `url(${poster})`;
      video.removeAttribute('poster');
    }

  });

})
