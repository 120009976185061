import React, { useState, useEffect } from 'react';
import './styles.scss';

const urlParams = new URLSearchParams(window.location.search);

function fetchSearch(searchQuery) {
  return fetch(`/wp-json/msc/v1/search?query=${searchQuery}`).then(response => response.json());
}

const SearchResults = (props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchLoading, setSearchLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {

    if (urlParams.has('query')) {
      const query = urlParams.get('query');
      setSearchQuery(query);

      fetchSearch(query).then(response => {

        if (response.length > 0) {
          setSearchResults(response);
        }

        setSearchLoading(false);

      });

    } else {
      setSearchLoading(false);
    }

  }, []);

  const numResults = searchResults.reduce((numResults, site) => {
    numResults = numResults + site.results.length;
    return numResults;
  }, 0);

  return (
    <div className="SearchResults">
      {searchLoading && <p>Loading...</p>}
      {searchResults.length > 0 && (
        <div className="SearchResults__results">
          <p>Your search for <strong>{searchQuery}</strong> returned <strong>{numResults}</strong> {searchResults.length === 1 ? 'result' : 'results'}:</p>
          {searchResults.map(site => (
            <div className="SearchResults__site" key={site.site_id}>
              <h2>{site.title}</h2>
              {site.results.map(page => (
                <div className="SearchResults__page" key={page.id}>
                  <h3>{page.title}</h3>
                  <p>{page.content}</p>
                  <a href={page.url} className="LinkText">View</a>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      {(!searchLoading && searchResults.length === 0) && (
        <p>No results for your search - <strong>{searchQuery}</strong>.</p>
      )}
    </div>
  );
};

export default SearchResults;
