import { showHeaderOverlay, hideHeaderOverlay } from './index';
import windowLoad from '../../utils/windowLoad';
import windowSize from '../../utils/windowSize';
import forEachElement from '../../utils/forEachElement';
import isTouch from '../../utils/isTouch';
import elementKeypress from '../../utils/elementKeypress';
import getElementIndex from '../../utils/getElementIndex';
import documentKeypress from '../../utils/documentKeypress';

const subNavOpenAttr = 'data-sub-nav-open';

windowLoad(e => {

  const headerNav = document.querySelector('.Header__site-nav > ul');
  const headerNavItems = document.querySelectorAll('.Header__site-nav > ul > li');
  const headerNavLinks = document.querySelectorAll('.Header__site-nav > ul > li > a');
  const headerSubNavLinks = document.querySelectorAll('.Header__site-nav > ul > li > ul > li > a');

  const openSubNav = (item) => {
    item.setAttribute(subNavOpenAttr, true);
    makeLinksTabbable(item, true);
  };

  const closeSubNav = (item) => {
    item.setAttribute(subNavOpenAttr, false);
    makeLinksTabbable(item, false);
  };

  const subNavOpen = (item) => {
    return item.getAttribute(subNavOpenAttr) === 'true';
  };

  const closeAllSubNav = () => {
    forEachElement(headerNavItems, closeSubNav);
    hideHeaderOverlay();
  };

  const getSubNavLinks = (item) => {
    return item.querySelectorAll('.Header__site-nav__sub-nav > li > a');
  };

  const makeLinksTabbable = (item, tabbable) => {
    const subNavLinks = getSubNavLinks(item);
    forEachElement(subNavLinks, link => {
      link.setAttribute('tabindex', tabbable ? '0' : '-1');
    });
  };

  const handleNavMenuClose = () => {
    closeAllSubNav();
  };

  const handleNavItemHover = (item) => {
    if (isTouch()) return;
    if (windowSize().width < 900) return;
    closeAllSubNav();
    openSubNav(item);
    showHeaderOverlay();
  };

  const handleNavItemClick = (e, item) => {
    if (windowSize().width >= 900) return;

    if (subNavOpen(item)) {
      if (!item.contains(e.target)) {
        closeAllSubNav();
      }
      return;
    }

    e.preventDefault();

    closeAllSubNav();
    openSubNav(item);
    showHeaderOverlay();
  };

  document.addEventListener('click', e => {
    if (!isTouch()) return;
    if (!headerNav.contains(e.target)) {
      handleNavMenuClose();
    }
  });

  headerNav.addEventListener('mouseout', e => {
    if (isTouch() || windowSize().width < 900) return;
    handleNavMenuClose();
  });

  forEachElement(headerNavItems, item => {
    closeSubNav(item);
    item.addEventListener('mouseover', () => handleNavItemHover(item));
    item.addEventListener('click', e => handleNavItemClick(e, item));

    const subNavLinks = getSubNavLinks(item);
    forEachElement(subNavLinks, link => {
      link.addEventListener('keydown', e => {
        const target = e.target;
        const targetIndex = getElementIndex(subNavLinks, target);
        const elementsLength = subNavLinks.length;
        if (e.shiftKey && e.key === 'Tab') {
          if (targetIndex === 0) {
            closeAllSubNav();
          }
        } else if (e.key === 'Tab') {
          if (targetIndex === elementsLength - 1) {
            closeAllSubNav();
          }
        }
      });
    });

  });

  const handleNavLinkKeypress = (link) => {
    const linkParent = link.parentNode;
    closeAllSubNav();
    openSubNav(linkParent);
    showHeaderOverlay();
    const subNavLinks = getSubNavLinks(linkParent);
    subNavLinks[0].focus();
  };

  forEachElement(headerNavLinks, link => {
      elementKeypress(link, (e, key) => {
      if (key === 'Enter') {
        e.preventDefault();
        handleNavLinkKeypress(link);
      }
    });
  });

  documentKeypress((e, key) => {
    if (key === 'Escape' && getElementIndex(headerSubNavLinks, e.target) > -1) {
      const parentItem = e.target.parentNode.parentNode.parentNode;
      closeAllSubNav();
      if (parentItem.children[0]) {
        parentItem.children[0].focus();
      }
    }
  }, 'keydown');

});
